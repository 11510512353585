<template>
  <div class="d-block">
    <b-list-group-item
      v-bind="$attrs"
      class="asset-list-item d-block py-lg-1"
      :data-ref="`asset${asset.id}`"
    >
      <div
        class="asset-item small py-lg-1"
        @click="$emit('asset-click', asset.id)"
      >
        <div v-if="!isMobile && showThumbnail" class="image-container d-flex">
          <img :src="asset.assetModel.thumbnail ? asset.assetModel.thumbnail.url : $emptyImage"/>
        </div>
        <label class="mb-0" style="flex: 1;" :for="`checkbox${asset.id}`">
          <div style="flex: 1;">
            <div class="d-flex" style="display: inline-block">
              <b-form-checkbox
                v-if="canSelect"
                :id="`checkbox${asset.id}`"
                :checked="selectedValues.includes(asset.id)"
                @input="$emit('check-item', $event)"
              >
              </b-form-checkbox>
              <AssetDetailsMobile
                v-if="isMobile"
                :asset='asset'
                :include-warranty="includeWarranty"
              />
              <AssetDetails
                v-else
                :asset='asset'
                :include-badges="includeBadges"
                :include-warranty="includeWarranty"
              />
            </div>
          </div>
        </label>
        <div style="margin-left: 1rem">
          <slot></slot>
        </div>
      </div>
      <div v-if="filteredServiceDetails.length" class="mt-2">
        <AssetServiceDetail
          v-for="(service, index) in filteredServiceDetails"
          :key="index"
          :service-detail="service"
          class="mb-2"
          @edit="onServiceDetailEdit"
          @updated="onServiceDetailUpdated"
        />
      </div>
      <div v-else-if="canAddServiceDetail">
        <div v-if="workOrder.service_completed_at" class="mt-2">
          <AssetServiceDetail class="mb-2" />
        </div>
        <div class="text-right">
          <b-button
            variant="link"
            class="mb-2"
            size="sm"
            @click="onAddServiceDetail"
          >
            <font-awesome-icon :icon="['far', 'plus']" class="mr-1" />
            Add Service Details
          </b-button>
        </div>
      </div>
    </b-list-group-item>

    <SidePanel
      ref="serviceDetailSidePanel"
      :header="activeServiceDetail ? 'Edit Service Detail' : 'Add Service Detail'"
      size="large"
    >
      <AssetServiceDetailForm
        :asset="asset"
        :work-order="workOrder"
        :service-detail="activeServiceDetail"
        @updated="onServiceDetailUpdated"
      />
    </SidePanel>
  </div>
</template>

<script>
import {BListGroupItem, BFormCheckbox, BButton} from 'bootstrap-vue';
import AssetDetailsMobile from '@/views/assets/AssetDetailsMobile.vue';
import AssetDetails from '@/views/assets/AssetDetails.vue';
import AssetServiceDetail from "@/components/asset/AssetServiceDetail.vue";
import AssetServiceDetailForm from "@/components/workOrder/assetServiceDetails/AssetServiceDetailForm.vue";
import SidePanel from "@/ux/SidePanel.vue";

export default {
  name: 'SingleAsset',
  inject: ["mq"],
  components: {
    SidePanel,
    BButton,
    AssetServiceDetailForm,
    AssetServiceDetail,
    AssetDetails,
    AssetDetailsMobile,
    BListGroupItem,
    BFormCheckbox,
  },
  props: {
    asset: {
      type: Object,
      required: true,
    },
    workOrder: {
      type: Object,
      default: () => {},
    },
    canSelect: {
      type: Boolean,
      default: true,
    },
    selectedValues: {
      type: Array,
      default: () => [],
    },
    showThumbnail: {
      type: Boolean,
      default: true,
    },
    includeBadges: {
      type: Boolean,
      default: true,
    },
    includeWarranty: {
      type: Boolean,
      default: true,
    },
    serviceDetails: {
      type: Array,
      default: () => [],
    },
    canAddServiceDetail: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'asset-click',
    'check-item',
    'service-detail-updated',
  ],
  data() {
    return {
      activeServiceDetail: null,
    };
  },
  computed: {
    isMobile() {
      return ['xs', 'sm', 'md'].includes(this.mq.current);
    },
    onScreenSizeTrigger() {
      if (!this.isMobile) {
        return 'hover';
      }
      return 'focus';
    },
    filteredServiceDetails() {
      const serviceDetails = this.serviceDetails;

      return serviceDetails
        .sort((a, b) => a.submitted_at - b.submitted_at);
    }
  },
  methods: {
    onAddServiceDetail() {
      this.$refs.serviceDetailSidePanel.show();
    },
    onServiceDetailEdit(serviceDetail) {
      this.activeServiceDetail = serviceDetail;
      this.$refs.serviceDetailSidePanel.show();
    },
    onServiceDetailUpdated() {
      this.$refs.serviceDetailSidePanel.hide();
      this.$emit('service-detail-updated');
    },
  },
};
</script>

<style lang="scss" scoped>
  .image-container {
    width: 100px; margin-right: 1.25rem; align-items: center;
    img {
      width: 100px; height: auto;
    }
  }
  .asset-list-item {
    cursor: pointer;
    &:hover {
      background-color: adjust-lightness($primary, 35%);
    }
  }
  .asset-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > label {
      cursor: pointer;
    }
  }
</style>
